jQuery(document).ready(function ($) {

    let cookie = $.cookie('product')

    $(window).scroll(function () {
        let topHeader = $('.header-top').innerHeight()
        let bottomHeader = $('.header-bottom').innerHeight()

        if ($(this).scrollTop() > topHeader) {
            $('.header-bottom').addClass('is-sticky')
            $('main').css('margin-top', bottomHeader + 'px')
        } else {
            $('.header-bottom').removeClass('is-sticky')
            $('main').removeAttr('style')
        }
    })

    $('.js-hamb').click(function () {
        $('#mobile').addClass('is-active')
        $('.bg').fadeIn(500)
        $('html, body').css('overflow', 'hidden')
    })

    $('.js-close, .bg').click(function () {
        $('#mobile').removeClass('is-active')
        $('.bg').fadeOut(500)
        $('html, body').removeAttr('style')
    })

    $('.nav-title-mob a').click(function () {
        $(this).parent().siblings('ul').slideToggle(500)
    })

    $('.js-view').on('click', function () {
        let productForm = $('.js-product-form')
        let prodId = productForm.attr('data-product')
        let prodTitles = productForm.attr('data-titles')
        console.log(prodTitles)


        $('#product_id').val(prodId)
        $('#your_product').val(prodTitles)

        $.fancybox.open({
            src: '#product-form', type: 'inline',
        })
    })

    document.addEventListener('wpcf7submit', function (event) {
        var productIdField = document.querySelector('input[name="product_id"]');
        if (!productIdField.value || productIdField.value == '') {
            event.preventDefault();
        }

        setTimeout(function () {
            $.fancybox.close()
        }, 3000)

    }, false)

    $('.js-fav').click(function () {
        let postID = $(this).data('id')
        const counter = $('.js-fav-icon').children('.counter')
        let counterVal = counter.html()
        // parseInt(postID)

        if (cookie && cookie !== null) {
            let prodArr = JSON.parse(cookie)
            let checkArray = prodArr.includes(postID)

            if (checkArray) {
                let newProdArr = prodArr.filter(function (elm) {
                    return elm != postID
                })

                if (newProdArr.length == 0) {
                    console.log(newProdArr)
                    $.cookie('product', '', { expires: 30, path: '/' })
                    counterVal = 0
                    counter.css('display', 'none').html(counterVal)
                }
                else {
                    let productsString = JSON.stringify(newProdArr)
                    $.cookie('product', productsString, { expires: 30, path: '/' })
                    counterVal--
                    counter.css('display', 'block').html(counterVal)
                }

            }
            else {
                prodArr.push(postID)
                let productsString = JSON.stringify(prodArr)
                $.cookie('product', productsString, { expires: 30, path: '/' })
                counterVal++
                counter.css('display', 'block').html(counterVal)
            }

        }
        else {
            let products = [postID]
            let productsString = JSON.stringify(products)
            $.cookie('product', productsString, { expires: 30, path: '/' })
            counterVal++
            counter.css('display', 'block').html(counterVal)
        }

        location.reload()
    })

    $('.js-fav-remove').click(function () {
        let postID = $(this).closest('.favorite-box-item').data('id')
        const counter = $('.js-fav-icon').children('.counter')
        let counterVal = counter.html()

        if (cookie && cookie != null) {
            let prodArr = JSON.parse(cookie)
            let checkArray = prodArr.includes(postID)
            if (checkArray) {
                let newProdArr = prodArr.filter(function (elm) {
                    return elm != postID
                })

                if (newProdArr.length == 0) {
                    $.cookie('product', '', { path: '/' });
                    // $.removeCookie('product')

                    counterVal = 0
                    counter.css('display', 'none').html(counterVal)
                    $(this).closest('.favorite-box-item').css('display', 'none')
                }
                else {
                    let productsString = JSON.stringify(newProdArr)
                    $.cookie('product', productsString, { expires: 30, path: '/' })
                    counterVal--
                    counter.css('display', 'block').html(counterVal)
                    $(this).closest('.favorite-box-item').css('display', 'none')
                }
            }

            location.reload()
        }
    })

    $('.js-pop').slick({
        slidesToShow:   3,
        slidesToScroll: 1,
        autoplay:       false,
        autoplaySpeed:  5000,
        arrows:         false,
        dots:           true,
        nextArrow:      '<span class="arrow arrow-right"><i class="fa fa-angle-right" aria-hidden="true"></i></span>',
        prevArrow:      '<span class="arrow  arrow-left"><i class="fa fa-angle-left" aria-hidden="true"></i></span>',
        responsive: [
            {
                breakpoint: 920,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                }
            }
        ]
    })

    $('#search-form').keyup(function () {
        let inputVal = $('#search-form').val()

        if (inputVal.length > 2) {
            let data = { action: 'search_query', 'search_val': inputVal }

            $.ajax({
                url:     myajax.url,
                type:    'post',
                data:    data,
                success: function (response) {

                    let result = JSON.parse(response)

                    $('#search-form').autocomplete({
                        source: result,
                        appendTo: '.header-search',
                        minLength: 2,
                    })
                },
            })
        }
    })


    const infoWindow = $('#info-modal')
    let sessionCookie = $.cookie('infoWindow')
    console.log(sessionCookie)

    if (!sessionCookie) {
        setTimeout(function () {
            infoWindow.addClass('active')
        }, 7000)
    }

    $('.info-modal-btn').click(function () {
        if (!sessionCookie) {
            $.cookie('infoWindow', 'true', { path: '/', expires: 1 });
            infoWindow.removeClass('active')
        }
    })

    const checkboxes = $('.wpcf7-list-item input[type="radio"]')

    checkboxes.each(function() {
        if ($(this).is(':checked')) {
            $(this).siblings('.wpcf7-list-item-label').addClass('checked');
        }
    })


    $('.wpcf7-list-item-label').click(function () {
        $(this).addClass('checked')
        $(this).siblings('input[type="radio"]').prop('checked', true)
        $(this).closest('.wpcf7-list-item').siblings('.wpcf7-list-item').find('.wpcf7-list-item-label').removeClass('checked')
        $(this).closest('.wpcf7-list-item').siblings('.wpcf7-list-item').find('input[type="radio"]').prop('checked', false)

        if ($('.wpcf7-list-item.last input[type="radio"]').is(':checked')) {
            $('.js-row').slideDown()
        } else {
            $('.js-row').slideUp()
        }
    })

    // anchor code
    var $page = $('html, body');
    $('a[href*="#"]').click(function() {
        event.preventDefault();
        $page.animate({
            scrollTop: $($.attr(this, 'href')).offset().top
        }, 1000);
        return false;
    });

    // on top
    const top_show = 300
    const delay = 500

    $(window).scroll(function () {
        if ($(this).scrollTop() > top_show) {
            $('.ontop').addClass('show')
        }
        else {
            $('.ontop').removeClass('show')
        }
    })
    $('.ontop').click(function () {
        $('body, html').animate({
            scrollTop: 0,
        }, delay)
    })
})